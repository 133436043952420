.jumbotron{
    background-image: url("../assets/banner3.jpg");
    background-size: cover;
    padding: 20vh;
}

.header-font{
    color: white;
}

.btn-style {
    border-color: white;
    border-width: 5px;
    background-color: transparent;
    transition: background-color 0.3s;
}

.btn-style:hover, .btn-style:focus, .btn-style:active {
    border-color: gray;
    background-color: transparent;
}

.vertical-menu {
    display: flex;
    flex-direction: column;
  }
  
  .ant-menu-submenu-popup .vertical-menu {
    flex-direction: row;
  }

  .horizontal-menu {
    display: flex;
  }
  
  .vertical-menu {
    display: block;
  }
  
  .custom-menu {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  .custom-menu .ant-menu-submenu-title:hover {
    color: pink !important;
  }
  
  .custom-menu .ant-menu-item,
  .custom-menu .ant-menu-submenu-title {
    color: black;
  }

  .skin-care-item:hover {
    font-weight: bold; /* Example: make the font bold */
  }

.menu-link {
  color: black;
  text-decoration: none;
}

.menu-link:hover {
  color: black;
}
  