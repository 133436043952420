.btn-shop{
    border-radius: 0%;
}

.card {
    border: 0px solid black;
}

.product-name, .product-price {
    font-weight: bold;
    text-align: left;
}

.product-detail {
    text-align: left;
}

a{
    text-decoration: none;
}