.jumbotron-search{
    background-size: cover;
    /*  */
}

.header-font{
    color: white;
}

.btn-style {
    border-color: white;
    border-width: 5px;
    background-color: transparent;
    transition: background-color 0.3s;
}

.btn-style:hover, .btn-style:focus, .btn-style:active {
    border-color: gray;
    background-color: transparent;
}


.split-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .split-image {
    flex: 50%;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    align-items: center;
    justify-content: center;
    padding: 50px;
    height: 50vh;
    border: solid 1px;
    position: relative;
  }
  
  .gender-option-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px;
    color: white;
  }

#menButton{
  transition: all 0.5s ease;
}

#menButton:hover{
  transform: scale(1.1) perspective(1px)
}

#womenButton{
  transition: all 0.5s ease;
}

#womenButton:hover{
  transform: scale(1.1) perspective(1px)
}


.jumbotron-option {
    /* filter: blur(5px); */
}

.search-components {
  padding: 5vh;
  display: flex;
}

.filter-section {
  flex: 0 0 12.5rem;
  border-right: 5px solid #ccc;
}

/* .items-section {
  display: flex;
  flex-wrap: wrap;
  padding: 1.3rem;
} */

.item {
  flex: 0 0 calc(33.33% - 20px); /* Adjust the width as needed */
  margin: 10px;
}

.filter-section ul {
  list-style: none;
  padding: 0;
}

.filter-section li {
  margin-bottom: 10px;
}

.items-section {
  overflow-y: scroll;
  height: 100vh;
}

.items-section h2 {
  margin-top: 0;
}

.items-section div {
  margin-bottom: 20px;
}

.items-section h3 {
  margin-top: 0;
}

.search-bar {
  background-color: black;
  padding: 3vh;
}

.ant-select-selector .ant-select-selection-placeholder {
  opacity: 1.0; /* Set the desired opacity value (0.0 to 1.0) */
  color: black;
}

a{
  text-decoration: none;
}

@media (max-width: 768px) {
    .split-container {
      width: 100%;
    }
  
    .split-image {
        flex: 100%;
    }
  }