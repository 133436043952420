.footer-content h5, .footer-content a{
    color: white;
}

.footer-content{
    padding-left: 150px;
    padding-right: 150px;
}

.footer {
    background-color: #333;
    color: #fff;
    padding: 30px 0;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .footer-column {
    /* flex to adjust how many column in one row */
    flex: 0 0 33.33%;
    margin-bottom: 30px;
  }
  
  h5 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  p {
    margin: 0;
  }
  
  .social-icons {
    margin-top: 10px;
  }
  
  .icon {
    display: inline-block;
    margin-right: 10px;
    font-size: 20px;
    color: #fff;
  }

  @media (max-width: 768px) {
    .footer-row {
      flex-direction: column;
    }
  
    .footer-column {
      flex: 0 0 100%;
    }
  
    .social-icons {
      margin-top: 10px;
    }
  }