.item-container {
  display: flex;
  padding: 5rem;
}

@media (max-width: 768px) {
  .item-container {
    flex-direction: column;
  }
}

.item-images {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  align-items: center;
}

.item-image{
  max-width: 50vh;
  margin-bottom: 20px;
}

.brand-image {
  max-width: 20vh;
}

.item-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item-name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.item-description {
  margin-bottom: 10px;
}

.item-price {
  font-size: 18px;
  font-weight: bold;
}

.checkout-button {
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.user-guide-container {
  max-height: 30vh;
  overflow: auto;
}

.item-description-long {
  margin-bottom: 0;
  text-align: start;
}
